/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useState, useContext, useEffect } from 'react'
import { injectIntl } from 'react-intl';
import { CommonContext } from 'shared/context/store/commonStore';
import PropTypes from 'prop-types';
import { Typography, Button } from 'components';
import { Filters } from 'popups';
import { validateToken, onLogout } from 'shared/services/authentication';
import BackIcon from 'assets/icons/arrow-left.svg';
import { loadLocalState } from 'shared/services/local.svc';

import './Navbar.scss';

const Navbar = ({
    intl,
    navTitle = 'Details',
    onBackBtnClick = () => {},
    rightBtnValue = intl.formatMessage({ id: 'filter.advancedSearch' }),
    rightBtnClick = () => {},
    isFilter = false,
    isHome = false,
}) => {
    const { state } = useContext(CommonContext);
    const [openModal, setOpenModal] = useState(false);
    const [isRM, setIsRM] = useState(false);
    const persistentState = loadLocalState();

    useEffect(() => {
        if (persistentState?.token) {
            validateToken().then(res => {
                setIsRM(res);
            });
        } else {
            setIsRM(false);
        }
        
    }, [persistentState?.token]);

    const onToggleModal = (e) => {
        e.preventDefault();
        
        if (isFilter) {
            setOpenModal(!openModal);
        }
        rightBtnClick();
    }

    const onHandleBackButton = (e) => {
        onBackBtnClick(e);
    }

    const onHandleReset = () => {
        console.log('invoked reset')
    }

    return (
        <>
            <header className="navbar">
                <nav>
                    {isHome ? null : (
                        <Button isIcon value={<img src={BackIcon} alt="back" />} onClick={onHandleBackButton} />
                    )}
                    <div className="navbar__title">
                        <Typography type="smallHighlight" textAlign="center" value={navTitle} />
                        {isRM ? <Typography type="smallHighlight" textAlign="center" value={intl.formatMessage({ id: 'user.markazStaff' })} /> : null }
                    </div>
                    <span className="desktop" />
                    <div>
                        <Button isLink value={rightBtnValue} onClick={onToggleModal} />
                        {isRM ? <Button isLink value="Logout" onClick={() => onLogout()} /> : null}
                    </div>
                </nav>
            </header>

            <Filters 
                showModal={openModal} 
                onReset={onHandleReset} 
                onConfirm={onToggleModal}
                // productType={filters.productType} 
            />
        </>
    )
}

Navbar.propTypes = {
    /** NavTitle */
    navTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Button Back Icon Click event */
    onBackBtnClick: PropTypes.func,
    /** Right button value */
    rightBtnValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Right button click event */
    rightBtnClick: PropTypes.func,
    /** Prop for if component is filter*/
    isFilter: PropTypes.bool,
}

export default injectIntl(memo(Navbar));
