import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './PerformanceTab.scss';

import { Typography } from 'components'

const Contents = ({
    annualizedReturn = '11.9 %',
    benchmarkReturn = '',
    annLabel = '',
    defaultStyle = true,
    intl
}) => {

    const classes = classNames({
        "contents": true,
        "contents--2ndStyle": !defaultStyle,
        "contents--noBenchmark": !benchmarkReturn || benchmarkReturn === '-'
    });

    // const typoClasses = classNames({
    //     "typography--markazCyan": !defaultStyle,
    //     "typography--markazPink": !defaultStyle,
    // });

    return (
        <section className={classes}>
                <div className="contents__item">
                    <Typography type="highlight" value={annualizedReturn} customClass={defaultStyle ? '' : "typography--markazCyan"} />
                    <Typography type="span" isLightColor isSmall value={intl.formatMessage({ id: annLabel })} />
                </div>
                {benchmarkReturn !== '-' && <div className="contents__item">
                    <Typography type="highlight" value={benchmarkReturn} customClass={defaultStyle ? '' : "typography--markazPink"}/>
                    <Typography type="span" isLightColor isSmall value={intl.formatMessage({ id: 'product.benchmarkReturn' })} />
                </div>}
        </section>
    )
}

Contents.propTypes = {
    /** Prop for annualizedReturn */
    annualizedReturn: PropTypes.string,
    /** Prop for benchmarkReturn */
    benchmarkReturn: PropTypes.string,
    /** Prop for content of Tab */
    defaultStyle : PropTypes.bool,
}

export default Contents;
