import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Typography.scss';

const TYPO_TYPE = {
    MAIN_TITLE: 'mainTitle',
    SUB_TITLE: 'subTitle',
    PARAGRAPH: 'paragraph',
    SPAN: 'span',
    HIGHLIGHT: 'highlight',
    SMALL_HIGHLIGHT: 'smallHighlight',
    MULTI_TEXT_SPAN: 'multiTextSpan',
    MULTI_TEXT_SUB_TITLE: 'multiTextSubTitle',
}

const Typography = ({
    type = TYPO_TYPE.SPAN,
    isSmall = false,
    isItalic = false,
    isBold = false,
    isLightColor = false,
    isTextColorInverted = false,
    isUppercase = false,
    textAlign = 'left',
    value = '',
    style,
    customClass
}) => {
    const classes = classNames({
        'typography': true,
        'typography--span': (type === TYPO_TYPE.SPAN || type === TYPO_TYPE.MULTI_TEXT_SPAN),
        'typography--mainTitle': type === TYPO_TYPE.MAIN_TITLE,
        'typography--subTitle': (type === TYPO_TYPE.SUB_TITLE || type === TYPO_TYPE.MULTI_TEXT_SUB_TITLE),
        'typography--paragraph': type === TYPO_TYPE.PARAGRAPH,
        'typography--highlight': type === TYPO_TYPE.HIGHLIGHT,
        'typography--highlightSmall': type === TYPO_TYPE.SMALL_HIGHLIGHT,
        'typography--small': isSmall,
        'typography--italic': isItalic,
        'typography--bold': isBold,
        'typography--light': isLightColor,
        'typography--invertedTextColor': isTextColorInverted,
        'typography--uppercase': isUppercase,
        [`typography--align-${textAlign}`]: true,
        [`${customClass}`]: true,
    })
    return (
        <>
            {type === TYPO_TYPE.SPAN && (
                <span className={classes} style={style}>{value}</span>
            )}

            {type === TYPO_TYPE.PARAGRAPH && (
                typeof value === 'string' ? <p className={classes} style={style}>{value}</p> : <article>{value}</article>
            )}

            {type === TYPO_TYPE.HIGHLIGHT && (
                <h2 className={classes} style={style}>{value}</h2>
            )}

            {type === TYPO_TYPE.SMALL_HIGHLIGHT && (
                <h4 className={classes} style={style}>{value}</h4>
            )}

            {type === TYPO_TYPE.MAIN_TITLE && (
                <h1 className={classes} style={style}>{value}</h1>
            )}

            {type === TYPO_TYPE.SUB_TITLE && (
                <h6 className={classes} style={style}>{value}</h6>
            )}
            {type === TYPO_TYPE.MULTI_TEXT_SPAN && (
                <span className={classes} style={style}>
                    {value && value.length && value.map((item, idx) => (
                        <span key={idx} className='d-flex'>{item}</span>
                    ))}
                </span>
            )}
            {type === TYPO_TYPE.MULTI_TEXT_SUB_TITLE && (
                <h6 className={classes} style={style}>
                    {value && value.length && value.map((item, idx) => (
                        <span key={idx} className='d-flex'>{item}</span>
                    ))}
                </h6>
            )}
        </>
    )
}

Typography.propTypes = {
    /** Heading type */
    type: PropTypes.oneOf([TYPO_TYPE.MAIN_TITLE, TYPO_TYPE.SUB_TITLE, TYPO_TYPE.PARAGRAPH, TYPO_TYPE.SPAN, TYPO_TYPE.HIGHLIGHT, TYPO_TYPE.SMALL_HIGHLIGHT, TYPO_TYPE.MULTI_TEXT_SUB_TITLE, TYPO_TYPE.MULTI_TEXT_SPAN]),
    /** is Small */
    isSmall: PropTypes.bool,
    /** is italic */
    isItalic: PropTypes.bool,
    /** is bold */
    isBold: PropTypes.bool,
    /** is text Light Color */
    isLightColor: PropTypes.bool,
    /** is text color white */
    isTextColorInverted: PropTypes.bool,
    /** is text all uppercase */
    isUppercase: PropTypes.bool,
    /** text alignment */
    textAlign: PropTypes.oneOf(['left', 'right', 'center']),
    /** text value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** custom style */
    style: PropTypes.any,
    /** option to dd custom class */
    customClass: PropTypes.string,
}

export default Typography
