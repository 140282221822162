import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Card.scss';

const Card = ({
    withShadow = true,
    isColoredBg = false,
    isTextCenter = false,
    children = <></>,
    isSmallPadding = false,
    customClass,
}) => {
    const classes = classNames({
        "card": true,
        "card--withShadow": withShadow,
        "card--coloredBg": isColoredBg,
        "card--textCenter": isTextCenter,
        "card--smallPadding": isSmallPadding,
        [customClass]: true,
    });

    const header = React.Children.map(children, child => child.type.displayName === 'Header' ? <header className={`card__header ${customClass}`}>{child}</header> : null);
    const body = React.Children.map(children, child => child.type.displayName === 'Body' ? child : null);
    
    return (
        <div className={classes}>
            {header}
            <section className="card__body">
                {body}
            </section>
        </div>
    )
}

Card.propTypes = {
    /** with shadow */
    withShadow: PropTypes.bool,
    /** is Colored background */
    isColoredBg: PropTypes.bool,
    /** Align text to center */
    isTextCenter: PropTypes.bool,
    /** Children node */
    children: PropTypes.node,
    /** is padding small */
    isSmallPadding: PropTypes.bool,
    /** extra className */
    customClass: PropTypes.string,
}

const Header = ({ children }) => children;
Header.displayName = 'Header';
Card.Header = Header;

const Body = ({ children }) => children;
Body.displayName = 'Body';
Card.Body = Body;

export default Card;
