export const ACTION_TYPES = {
    SAVE_LANGUAGE: 'SAVE_LANGUAGE',
    UPDATE_CATALOGUE: 'UPDATE_CATALOGUE',
    ADD_FILTER_PRODUCT_TYPE: 'ADD_FILTER_PRODUCT_TYPE',
    ADD_FILTER_GEOGRAPHY: 'ADD_FILTER_GEOGRAPHY',
    SAVE_MINIMUM_INVESTMENT: 'SAVE_MINIMUM_INVESTMENT',
    SAVE_MINIMUM_INVESTMENT_UNIT: 'SAVE_MINIMUM_INVESTMENT_UNIT',
    IS_MIN_INVESTMENT_ACTIVE: 'IS_MIN_INVESTMENT_ACTIVE',
    RESET_FILTERS: 'RESET_FILTERS',
    UPDATE_SWITCH_FILTER: 'ADD_FILTER_INVESTOR_TYPE',
    UPDATE_MASTER_LIST: 'UPDATE_MASTER_LIST',
    SAVE_PRODUCT_DETAIL: 'SAVE_PRODUCT_DETAIL',
    OPEN_MODAL: 'OPEN_MODAL',
    // SAVE_IS_RM: 'SAVE_IS_RM',
}