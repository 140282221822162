const LANGUAGE = {
    EN: 'en',
    AR: 'ar'
}

const PRODUCT_SERVICE_TYPE = {
    INVESTMENT_SERVICE: 'Investment Service',
    MUTUAL_FUND: 'Mutual Fund',
    REAL_ESTATE: 'Real Estate Product'
}

const INVESTMENT_SERVICE_TYPE = {
    TYPE_1: 'Type 1',
    TYPE_2: 'Type 2'
}

const PRODUCT_DISPLAY_TEMPLATE = {
    MF_EQUITY: 'mfEquity',
    MF_REAL_ESTATE: 'mfRealEstate',
    GCC_REAL_ESTATE: 'gccRealEstate',
    INTL_REAL_ESTATE: 'intlRealEstate',
    INVESTMENT_SERVICE: 'investmentService'
}

const MUTUAL_FUND_TYPE = {
    PUBLIC: 'Public',
    PRIVATE: 'Private'
}

const SALES_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
}

const CURRENCY = {
    KWD: 'KWD',
    USD: 'USD'
}

const PAGE_URL = {
    HOME: '/',
    PRODUCT_DETAILS: '/product-details',
    APPOINTMENT: '/appointment',
    THANKYOU: '/thank-you',
    RESTRICTED: '/restricted',
    LOGIN: '/restricted/login',
    OTP: '/restricted/otp',
}

const TAGS_TYPE = {
    CATEGORY1: 'category1',
    CATEGORY2: 'category2',
    INVESTOR_TYPE: 'investorType'
}

const const_vars = {
    LANGUAGE,
    PRODUCT_SERVICE_TYPE,
    MUTUAL_FUND_TYPE,
    SALES_STATUS,
    CURRENCY,
    PAGE_URL,
    TAGS_TYPE,
    COLORS: ['#005698', '#CBB565', '#B3DE69', '#00AEEF', '#E48196', '#BEBADA'],
    PRODUCT_DISPLAY_TEMPLATE,
    INVESTMENT_SERVICE_TYPE
}

export default const_vars;