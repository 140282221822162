import React, { useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tabs.scss';

const Tabs = ({
    tabMenu = [],
    children,
    tabActive = 0,
    onTabClick = () => {},
}) => {
    const [listMenu, setListMenu] = useState(tabMenu);
    const [currentTab, setCurrentTab] = useState(tabActive);

    useEffect(() => {
        setListMenu(tabMenu);
    }, [tabMenu]);
  
    const handleClick = (item, idx) => {
    setCurrentTab(idx);
    onTabClick(item);
    };

    const classes = classNames({
        "tabs": true,
    });

    return (
        <div className={classes}>
        <ul className="tabs__menu">
          {listMenu && listMenu.length > 0
            ? listMenu.map((item, idx) => {
                return (
                  <li
                    key={`tabs${idx}`}
                    className={`${currentTab === idx ? "active" : ""}`}
                  >
                    <span onClick={() => handleClick(item.title, idx)}>
                      {item.title}
                    </span>
                  </li>
                );
              })
            : null}
        </ul>
        <div className="tabs__content">
          {Children.map(children, (child, i) => (
            <div
              className={`tabs__content--normal ${
                currentTab === i ? "tabs__content--active" : "tabs__content--inactive"
              }`}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    )
}

Tabs.propTypes = {
    /** Prop for the tab menu/ header */
    tabMenu: PropTypes.array,
    /** Prop for children */
    children: PropTypes.array,
    /** Prop activitiy of tab */
    tabActive: PropTypes.number,
    /** Prop for on click function */
    onTabClick: PropTypes.func,
}

export default Tabs;
