
const getTranslateMap = new Map([
    ['Conventional', 'تقليدية '],
    ['GCC', 'مجلس التعاون الخليجي'],
    ['GCC countries', 'دول مجلس التعاون الخليجي'],
    ['GCC Discretionary', 'الاستثمار الاختياري في منطقة الخليج'],
    ['GCC Equity', 'الأسهم الخليجية'],
    ['GCC Fixed Income', 'الدخل الثابت الخليجية'],
    ['GCC Real Estate', 'العقارات الخليجية'],
    ['GCC, USA Discretionary', 'الاستثمار الاختياري الخليج, الولايات المتحدة'],
    ['GCC, USA Equity', 'الأسهم  الخليج, الولايات المتحدة'],
    ['GCC, USA Fixed Income', ' الدخل الثابت الخليج ,لولايات المتحدة'],
    ['GCC, USA Real Estate', 'العقارات الخليج, الولايات المتحدة'],
    ['GCC, Europe Discretionary', ' الاستثمار الاختياري الخليج, أوروبا'],
    ['GCC, Europe Equity', ' الأسهم الخليج, أوروبا'],
    ['GCC, Europe Fixed Income', ' الدخل الثابت الخليج, أوروبا'],
    ['GCC, Europe Real Estate', 'العقارات الخليج, أوروبا'],
    ['Global', 'عالمي'],
    ['global', 'عالمي'],
    ['Global Discretionary', ' الاستثمار الاختياري العالمي'],
    ['Global Equity', ' الأسهم عالمي'],
    ['Global Fixed Income', ' الدخل الثابت عالمي'],
    ['Global Real Estate', 'العقارات عالمي'],
    ['Europe', 'أوروبا'],
    ['Europe Discretionary', ' الاستثمار الاختياري في أوروبا'],
    ['Europe Equity', ' الأسهم  في أوروبا'],
    ['Europe Fixed Income', ' الدخل الثابت في أوروبا'],
    ['Europe Real Estate', 'العقارات الأوروبية'],
    ['Europe, USA Discretionary', ' الاستثمار الاختياري الأمريكي في أوروبا'],
    ['Europe, USA Equity', ' الأسهم الأمريكية في أوروبا'],
    ['Europe, USA Fixed Income', ' الدخل الثابت الأمريكية في أوروبا'],
    ['Europe, USA Real Estate', 'العقارات الأمريكية في أوروبا'],
    ['USA', 'الولايات المتحدة الأمريكية'],
    ['USA Discretionary', ' الاستثمار الاختياري في الولايات المتحدة '],
    ['USA Equity', ' الأسهم في الولايات المتحدة '],
    ['USA Fixed Income', ' الدخل الثابت في الولايات المتحدة '],
    ['USA Real Estate', 'العقارات في الولايات المتحدة '],
    ['For Qualified Investors', 'للمستثمرين المؤهلين'],
    ['Mutual Fund', 'صندوق مشترك '],
    ['Real Estate Product', 'منتج عقاري'],
    ['Investment Service', 'خدمة استثمارية '],
    ['year', 'سنة'],
    ['years', 'سنوات'],
    ['Shari\'ah', 'الشريعة '],
]);

const getTranslation = (code, language = 'en') => {
    switch(language) {
        case 'ar':
            return getTranslateMap.get(code) !== undefined ? getTranslateMap.get(code) : code;
        default:
            return code;
    }
}

export default getTranslation;