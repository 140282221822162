import React, { memo } from 'react';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConnectedIntlProvider from 'shared/helpers/ConnectedIntlProvider';
import { CatalogueContextProvider } from 'shared/context/store/catalogueStore';
import { CommonContextProvider } from 'shared/context/store/commonStore';

import {
  // useQuery,
  // useMutation,
  // useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

/** Create client */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <CatalogueContextProvider>
      <CommonContextProvider>
        <QueryClientProvider client={queryClient}>
          <ConnectedIntlProvider>
              <main className="product-catalogue-app" id="app">
                  <ToastContainer />
                  <Routes />
              </main>
              <ReactQueryDevtools initialIsOpen position="bottom-right" />
          </ConnectedIntlProvider>
        </QueryClientProvider>
      </CommonContextProvider>
    </CatalogueContextProvider>
  );
}

export default memo(App);
