import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.scss';

const Button = ({
    isPrimary = false,
    isSecondary = false,
    isTertiary = false,
    isOutline = false,
    isLink = false,
    isIcon = false,
    isBig = false,
    isDisabled = false,
    isHidden = false,
    type = 'button',
    name = 'button',
    value = 'Label here',
    customClass = '',
    onClick = () => {},
    isFullWidth = false
}) => {
    const classes = classNames({
        "btn": true,
        "btn--primary": isPrimary,
        "btn--secondary": isSecondary,
        "btn--tertiary": isTertiary,
        "btn--outline": isOutline,
        "btn--link": isLink,
        "btn--icon": isIcon,
        "btn--big": isBig,
        "btn--disabled": isDisabled,
        "btn--fullWidth": isFullWidth,
        [customClass]: true,
    });

    return (
        <button
            type={type}
            name={name}
            className={classes}
            onClick={onClick}
            disabled={isDisabled}
            hidden={isHidden}
        >
            {value}
        </button>
    )
}

Button.propTypes = {
    /** is Primary color */
    isPrimary: PropTypes.bool,
    /** is Secondary color */
    isSecondary: PropTypes.bool,
    /** is Tertiary color */
    isTertiary: PropTypes.bool,
    /** is button an outline? */
    isOutline: PropTypes.bool,
    /** is button looks like a Link */
    isLink: PropTypes.bool,
    /** is button an Icon */
    isIcon: PropTypes.bool,
    /** is Button big */
    isBig: PropTypes.bool,
    /** is Disabled */
    isDisabled: PropTypes.bool,
    /** is Hidden */
    isHidden: PropTypes.bool,
    /** Button Event */
    onClick: PropTypes.func,
    /** Button type */
    type: PropTypes.string,
    /** Button Name */
    name: PropTypes.string,
    /** Button Value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** optional: custom class */
    customClass: PropTypes.string,
}

export default Button;
