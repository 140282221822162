import React, { useReducer, createContext, useMemo } from 'react';
import catalogueReducer from '../reducers/catalogueReducer';
import const_vars from 'shared/constant';

/** @TODO USE THIS ONCE CatalogueContextProvider is no longer needed */

const { PRODUCT_SERVICE_TYPE } = const_vars;

export const CatalogueContext = createContext();

export const initialState = {
    catalogue: [],
    productDetail: {},
    masterData: [],
    filters: {
        productType: [{
            label: PRODUCT_SERVICE_TYPE.MUTUAL_FUND,
            name: 'mutualFund',
            value: true
        }, {
            label: PRODUCT_SERVICE_TYPE.REAL_ESTATE,
            name: 'realEstate',
            value: true
        }, {
            label: PRODUCT_SERVICE_TYPE.INVESTMENT_SERVICE,
            name: 'investmentService',
            value: true
        }],
        geography: [{
            label: 'GCC countries',
            name: 'gccCountries',
            value: true
        }, {
            label: 'USA',
            name: 'usa',
            value: true
        }, {
            label: 'Europe',
            name: 'europe',
            value: true
        }],
        isMinInvestmentActive: false,
        minimumInvestment: 10000,
        minimumInvestmentUnit: 'KWD',
        switchFilter : {
            "shariahOrAll": {
                name: "shariahOrAll",
                labels: ["filter.allInvestments", "filter.shariah"],
                checkedState: [true, false]
            },
            "retailOrQualified": {
                name: "retailOrQualified",
                labels: ["filter.retailInvestor", "filter.qualifiedInvestor"],
                checkedState: [false, true]
            },
        },
        
    },
    error: null
};

export const CatalogueContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(catalogueReducer, initialState);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <CatalogueContext.Provider value={contextValue}>
          {children}
        </CatalogueContext.Provider>
    );
}