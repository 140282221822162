import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, LineChart, Line, XAxis, CartesianGrid, } from 'recharts';

import const_vars from 'shared/constant';
const { COLORS } = const_vars;

const Chart = ({
    name = 'chart',
    data = [],
    thickness = 20,
    outerRadius = 80,
    chartType,
    width,
    height,
}) => {
    // const COLORS = ['#005698', '#CBB565', '#b3de69', '#00AEEF', '#E48196', '#BEBADA'];

    /**
    const lineChartData = [
        {
            name: '2016',
            y1: 4000,
            y2: 2400,
          },
          {
            name: '2017',
            y1: 3000,
            y2: 1398,
          },
          {
            name: '2018',
            y1: 2000,
            y2: 9800,
          },
          {
            name: '2019',
            y1: 2780,
            y2: 3908,
          },
          {
            name: '2020',
            y1: 1890,
            y2: 4800,
          },
    ]
     */
    // const examplePieData = [
    //     { name: 'Group A', value: 400 },
    //     { name: 'Group B', value: 300 },
    //     { name: 'Group C', value: 300 },
    //     { name: 'Group D', value: 200 },
    //     { name: 'Group E', value: 278 },
    //     { name: 'Group F', value: 189 },
    //   ];
    
    return (
        <ResponsiveContainer width={width || '100%'} height={height || outerRadius*2} >
            {chartType === 'Pie' ? (
                    <PieChart>
                        <Pie
                            // data={exampleData}
                            data={data}

                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={outerRadius}
                            innerRadius={outerRadius - thickness}
                            dataKey="value"
                            paddingAngle={ data.length * -1.5 }
                            startAngle={180}
                            endAngle={-180} 
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} cornerRadius={thickness} />
                            ))}
                        </Pie>
                    <Tooltip />
                </PieChart>
            ) : (
                <LineChart
                    width={'100%'}
                    height={'100%'}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid horizontal={false} />
                    <XAxis dataKey="name" axisLine={false} tickLine={false}/>
                    <Tooltip formatter={ (value) => [value] } />
                    <Line
                        type="monotone"
                        dataKey={'value'}
                        stroke={'#00AEEF'}
                        strokeWidth={3}
                        dot={false}
                        // name = {lineAliasName || line}
                        activeDot={{ r: 8 }}
                        // key={idx}
                    />
                    {/* <Line
                        type="monotone"
                        dataKey={'y2'}
                        stroke={'#E48196'}
                        strokeWidth={3}
                        dot={false}
                        // name = {lineAliasName || line}
                        activeDot={{ r: 8 }}
                        // key={idx}
                    /> */}
                </LineChart>    
            )}
        </ResponsiveContainer>
    );
}

Chart.propTypes = {
    /** Pie Chart Data */
    data: PropTypes.arrayOf(PropTypes.object),

    /** Thickness of Pie Chart */
    thickness: PropTypes.number,
    /** Outer Radius of Pie Chart */
    outerRadius: PropTypes.number,
    /** Either Pie Chart or Line Chart */
    chartType: PropTypes.oneOf(['Pie', 'Line']),
    /** Width of chart */
    width: PropTypes.number,
    /** Height of chart */
    height: PropTypes.number,
}



export default Chart