import React, { useState, memo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import { Modal, WeekDayPicker } from 'components';

const Typography = loadable(() => import('../../components/Typography'));
const Button = loadable(() => import('../../components/Button'));

const SelectDate = ({
    intl,
    showModal,
    onChangeStep = () => {},
    value,
    rtl,
    onClose = () => {},
}) => {
    const [dateValue, setDateValue] = useState();

    const handleSubmit = (val, option) => {
        const dateAppointment = option.pureDate.locale('en').format('dddd, DD MMMM YYYY');
        setDateValue(dateAppointment)
        onChangeStep(dateAppointment);
    };

    const handleBtnSubmit = (dateVal) => {
        onClose(dateVal)
    }

    return (
        <Modal isModalDynamic showModal={showModal} bodyPadding="0px 20px">
            <Modal.Header>
                <Typography type="span" isBold value={intl.formatMessage({ id: 'select.date.title' })} />
            </Modal.Header>
            <Modal.Body>
                <WeekDayPicker
                    onSubmit={handleSubmit}
                    value={value}
                    rtl={rtl}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="selectDate__submitBtn" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        isPrimary
                        value={intl.formatMessage({ id: 'select.date.submit' })}
                        onClick={(e) => handleBtnSubmit(dateValue)}
                        isDisabled={dateValue && (dateValue === undefined || dateValue.length>0) ? false : true}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

SelectDate.propTypes = {
    /** is modal open */
    showModal: PropTypes.bool,
    /** Submit function */
    onChangeStep: PropTypes.func,
    /** On Close function */
    onClose: PropTypes.func,
}

export default memo(injectIntl(SelectDate))