import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Contents from './Contents';
import { Tabs } from 'components';
import './PerformanceTab.scss';

const PerformanceTab = ({
    intl,
    tabsMenu = [{
        title: intl.formatMessage({ id: 'product.sinceInception' })
    }, {
        title: intl.formatMessage({ id: 'product.5y' })
    }, {
        title: intl.formatMessage({ id: 'product.3y' })
    }, {
        title: intl.formatMessage({ id: 'product.1y' })
    }, {
        title: intl.formatMessage({ id: 'product.3m' })
    }],
    id = '',
    contents = [],
    defaultStyle,
}) => {
    return (
        <section className="performanceTab">
            <Tabs
                tabMenu={tabsMenu}
                tabActive={1}
            >
                {contents && contents.map((item, idx) => (
                    <Contents 
                        key={`${id}-${idx}`}
                        {...item}
                        defaultStyle={defaultStyle}
                        intl={intl}
                    />
                ))}
            </Tabs>
        </section>
    )
}

PerformanceTab.propTypes = {
    /** Prop for the tab menu/ header */
    tabsMenu: PropTypes.arrayOf(PropTypes.object),
    /** Prop for details */
    contents: PropTypes.arrayOf(PropTypes.object),
    /** Prop for id */
    id: PropTypes.any,
    /** Prop for content of Tab */
    defaultStyle : PropTypes.bool,
}

export default injectIntl(PerformanceTab);
