import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { CommonContext } from 'shared/context/store/commonStore';
import './Disclaimer.scss';

const Disclaimer = ({intl}) => {
    const { state: commonState } = useContext(CommonContext);
    const { language: { locale } } = commonState;

    return (
        <div className="disclaimer-section" dir={locale === 'ar' ? 'rtl': ''}>
            <h2>{intl.formatMessage({ id: 'disclaimer.title' })}</h2>
            <section dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'disclaimer.content' }) }} />
        </div>
    )
}

export default injectIntl(Disclaimer);