import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import moment from 'moment';
import { injectIntl } from 'react-intl';

import './WeekDayPicker.scss';

const WeekDayPicker = (props) => {
    const { value } = props;

    /**
     * Submit date selected
     * @param {dateSelected} e
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const checkbox = e.target.querySelector('input');

        if (checkbox.disabled) return;
        if (checkbox.checked === false) {checkbox.checked = !checkbox.checked;}
        const { onSubmit } = props;
        onSubmit(
            moment(parseInt(checkbox.value)).format('dddd, D MMMM YYYY'), { toTranslate: false, pureDate: moment(parseInt(checkbox.value)) },
        );
    };

    /**
     * Added initial date to make appointment only after may 01
     */
    const initialDate = moment().isAfter(moment('2020-05-01')) ? new Date() : '2020-05-01';

    /**
     * Render days of week
     * @param {*} firstDay
     */
    const renderDaysOfWeek = (firstDay) => {
        const daysOfWeek = [];

        for (let i = firstDay; i < (firstDay + 5); i++) {
            daysOfWeek.push(
                <li
                    key={i}
                    className={`select-option ${value === moment(initialDate).day(i).format('dddd, D MMMM YYYY') ? 'selected' : ''} ${moment(initialDate).day(i).isSameOrBefore(moment().add(2, 'day'), 'day') ? 'select-option--disabled' : ''}`}
                    onClick={handleSubmit}
                >
                <label>
                    <input
                        type="radio"
                        defaultChecked={value === moment(initialDate).day(i).format('dddd, D MMMM YYYY')}
                        name="weekdaypicker"
                        value={moment(initialDate).day(i)}
                        disabled={moment(initialDate).day(i).isSameOrBefore(moment().add(2, 'day'), 'day')}
                    />
                    <span className="select-option__checkmark" />
                    <span className="select-option__text">{moment(initialDate).day(i).format('dddd, D MMMM')}</span>
                </label>
                </li>,
            );
        }
        return daysOfWeek;
    };

    /**
     * Render Weeks
     */
    const renderWeeks = () => {
        const weeks = [];
        const { intl, startOfWeek } = props;
        const firstDay = startOfWeek === 'saturday' ? 7 : 1;

        for (let i = 0; i < 4; i += 1) {
            weeks.push(
                <section className="weekday-picker__container" key={`week${i}`}>
                    <div className="weekday-picker__title">
                        {intl.formatMessage({ id: 'weekday.picker.title' }, { day: moment(initialDate).day(firstDay + i * 7).format('D MMMM') })}
                    </div>
                    <ul>{renderDaysOfWeek(firstDay + i * 7)}</ul>
                </section>,
            );
        }
        return weeks;
    };

    /**
     * Slider settings
     */
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: props.rtl,
        // initialSlide: moment().isSameOrAfter(moment().day(5)) ? 1 : 0, // move to next week if today is friday of this week
        initialSlide: 0,
    };

    const classes = classNames({
        'weekday-picker': true,
        'weekday-picker--rtl': props.rtl,
    });

    return (
        <Slider {...settings} className={classes}>
            {renderWeeks()}
        </Slider>
    );
};

WeekDayPicker.propTypes = {
    /** Start of the week */
    startOfWeek: PropTypes.string,
    /** Day Selection */
    onSubmit: PropTypes.func,
    /** Is right to left */
    rtl: PropTypes.bool,
};

WeekDayPicker.defaultProps = {
    startOfWeek: 'saturday',
    onSubmit: () => {},
    rtl: false,
};

export default injectIntl(WeekDayPicker);