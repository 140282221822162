import React, { useContext, useCallback, useMemo, memo } from 'react'; // useRef, useEffect, 
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import * as utils from 'shared/helpers/utils';
import getTranslation from 'shared/translations/getTranslation';
import BackIcon from 'assets/icons/arrow-left.svg';

import './Filter.scss';

import { ACTION_TYPES } from 'shared/constant/actionTypes';
import { CatalogueContext } from 'shared/context/store/catalogueStore';
import { CommonContext } from 'shared/context/store/commonStore';
import { Card, Dropdown, Modal } from 'components';

const Switch = loadable(() => import('../../components/Switch'));
const Typography = loadable(() => import('../../components/Typography'));
const Button = loadable(() => import('../../components/Button'));
// const Tooltip = loadable(() => import('../../components/Tooltip'));
const ProductSlider = loadable(() => import('../../components/ProductSlider'));

const Filters = ({
    intl,
    showModal,
    isModal = true,
    onConfirm = () => {},
    onReset = () => {},
}) => {
    const {state, dispatch} = useContext(CatalogueContext);
    const { state: commonState } = useContext(CommonContext);
    const { language: { locale } } = commonState;
    const { masterData, filters: { productType, geography, isMinInvestmentActive }} = state;
    // const [showTooltipContent, setShowTooltipContent] = useState(false);
    // const [currentId, setCurrentId] = useState();

    // const productServiceId = "productService"
    // const geographyId = "geography"
 
    /**
     * Handle Product Type Filter selection
     */
    const onProductTypeSelection = useCallback(({ target: { name, checked } }) => {
        const productTypeCopy = [...productType];
        const productIndex = productTypeCopy.findIndex(item => item.name === name);
        productTypeCopy[productIndex].value = checked;

        dispatch({
            type: ACTION_TYPES.ADD_FILTER_PRODUCT_TYPE,
            payload: productTypeCopy
        });
    }, [productType, dispatch]);

    /**
     * Handle Geography Filter selection
     */
     const onGeographySelection = useCallback(({ target: { name, checked } }) => {
        const geographyCopy = [...geography];
        const geographyIndex = geographyCopy.findIndex(item => item.name === name);
        geographyCopy[geographyIndex].value = checked;

        dispatch({
            type: ACTION_TYPES.ADD_FILTER_GEOGRAPHY,
            payload: geographyCopy
        });
    }
    , [geography, dispatch]
    );

    /**
     * Reset Filters back to initial states
     */
    const onHandleReset = () => {
        dispatch({
            type: ACTION_TYPES.RESET_FILTERS,
        });
        onReset();
    };

    /**
     * Save Minimum Investment Slider Value
     */
     const saveMinimumInvestment = useCallback((payload)  => {
        dispatch({
            type: ACTION_TYPES.SAVE_MINIMUM_INVESTMENT,
            payload,
        });
    }, [dispatch]);

    /**
     * Return all the units in master data
     * @returns minimum initial amount units
     */
    const minimalInitialAmountUnitArr = useMemo(() => {
        const masterDataCopy = [...masterData];
        const unitsArray = masterDataCopy.map(
            function (unit) {
                return unit.name
            }
        )
        return unitsArray
    }, [masterData]);

    /**
     * Save Minimum Investment Dropdown Unit
     * Saved value: minimumInvestmentUnit
     */
     const saveMinimalInitialAmountUnit = useCallback((payload)  => {
        dispatch({
            type: ACTION_TYPES.SAVE_MINIMUM_INVESTMENT_UNIT,
            payload,
        });
    }, [dispatch]);

    // Min Investment Switcher (On/Off)
    const switchMinInvestment = useCallback((value) => {
        const payload = value;
        dispatch({
            type: ACTION_TYPES.IS_MIN_INVESTMENT_ACTIVE,
            payload,
        })
    }, [dispatch]);

    const handleUnitChange = (e) => {
        saveMinimalInitialAmountUnit(e.target.value)
    }

    /**
     * Toogle the Tooltip
     */
    // const toggleTooltip = useCallback(
    //     event => {
    //         event.preventDefault();
    //         setCurrentId(event.currentTarget.name)
    //         setShowTooltipContent(!showTooltipContent);
    //     },
    //     [showTooltipContent]
    // );

    // const getTooltipContent = id => {
    //     switch (id) {
    //         case productServiceId:
    //             return "Lorem ipsum"

    //         case geographyId:
    //             return "Lorem ipsum";

    //         default:
    //             return null;
    //     }
    // }

    // const tooltipRef = useRef(null);
    // const handleClickOutside = useCallback(
    //     (event) => {
    //         if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
    //             setShowTooltipContent(false);
    //         }
    //       },
    //     [setShowTooltipContent]
    // );
    
    // useEffect(() => {
	//     document.addEventListener("click", handleClickOutside, true);
	// 	return () => {
	// 		document.removeEventListener("click", handleClickOutside, true);
	// 	};
	// }, [handleClickOutside]);

    const renderFilterComponents = () => (
        <>
            <Card isColoredBg withShadow={false}>
                    <Card.Header>
                        <div>
                            <Typography type="subTitle" isUppercase value={intl.formatMessage({ id: 'filter.productOrService' })} />
                            {/* <div ref={tooltipRef}>
                                <Button
                                    isIcon
                                    name={productServiceId}
                                    onClick={(e) => {toggleTooltip(e)}}
                                    value={<img src={InfoIcon} alt="icon" width={20} height={20} />}
                                />
                                <Tooltip
                                    showTooltip={showTooltipContent && currentId === productServiceId}
                                    id={productServiceId}
                                    content={getTooltipContent(currentId)}
                                />
                            </div> */}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {productType && productType.map((item, idx) => (
                            <Switch 
                                key={`prodType-${idx}`}
                                value={item.value}
                                label={getTranslation(item.label, locale)}
                                id={item.name}
                                name={item.name}
                                onChange={onProductTypeSelection}
                            />
                        ))}
                    </Card.Body>
                </Card>

                <Card isColoredBg withShadow={false}>
                    <Card.Header>
                        <div>
                            <Typography type="subTitle" isUppercase value={intl.formatMessage({ id: 'filter.geography' })} />
                            {/* <div ref={tooltipRef}>
                                <Button 
                                    isIcon
                                    name={geographyId}
                                    onClick={(e) => {toggleTooltip(e)}}
                                    value={<img src={InfoIcon} alt="icon" width={20} height={20} />} 
                                />
                                <Tooltip
                                    showTooltip={showTooltipContent && currentId === geographyId}
                                    id={geographyId}
                                    content={getTooltipContent(currentId)}
                                />
                            </div> */}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {geography && geography.map((item, idx) => (
                            <Switch 
                                key={`geog-${idx}`}
                                value={item.value}
                                label={getTranslation(item.label, locale)}
                                id={item.name}
                                name={item.name}
                                onChange={onGeographySelection}
                            />
                        ))}
                    </Card.Body>
                </Card>

                <Card isColoredBg withShadow={false}>
                    {/* <Card.Header>
                        <Typography type="subTitle" isUppercase value={intl.formatMessage({ id: 'filter.minInvestment' })} customClass="typography--removeWrapperMargin"/>
                        <div style={{minWidth:'34%', justifyContent:'space-between'}}>
                            <Dropdown
                                name='minimumInvestment'
                                defaultValue={state.filters.minimumInvestmentUnit}
                                options={minimalInitialAmountUnitArr}
                                onChange={e => handleUnitChange(e)}
                                // isDisabled
                            />
                            <Typography style={{ marginLeft: '5px' }} type="highlight" isSmall value={`${utils.formatAmount(state.filters.minimumInvestment)}`} />
                        </div>
                    </Card.Header> */}
                    <Card.Body>
                        <Switch 
                            label={intl.formatMessage({ id: 'filter.minInvestment' })}
                            value={isMinInvestmentActive}
                            id="isMinInvestmentActive"
                            name="isMinInvestmentActive"
                            onChange={e => switchMinInvestment(e.target.checked)}
                        />
                        <div className="min-investment-switch">
                            <Dropdown
                                name='minimumInvestment'
                                defaultValue={state.filters.minimumInvestmentUnit}
                                options={minimalInitialAmountUnitArr}
                                onChange={e => {
                                    if (e.target.value === 'Unit') {
                                        saveMinimumInvestment(100);
                                    } else {
                                        saveMinimumInvestment(1000);
                                    }
                                    handleUnitChange(e);
                                }}
                                isDisabled
                            />
                            <Typography style={{ marginLeft: '5px' }} type="highlight" isSmall value={`${utils.formatAmount(state.filters.minimumInvestment)}`} />
                        </div>
                        <ProductSlider
                            name='minimumInvestment'
                            defaultValue={state.filters.minimumInvestment}
                            step={1000}
                            minValue={1000}
                            maxValue={500000}
                            value={state.filters.minimumInvestment}
                            onChange={(name, val) => {
                                saveMinimumInvestment(val);
                            }}
                            isDisabled={!isMinInvestmentActive}
                        />
                    </Card.Body>
                </Card>
        </>
    )
 
    return (
        <>
        {isModal ? (
            <Modal showModal={showModal} bodyPadding="0px 20px 20px">
            <Modal.Header>
                <div className="modal-header__title">
                    <Button isIcon value={<img src={BackIcon} alt="back" />} onClick={onConfirm} />
                    <Typography type="span" value={intl.formatMessage({ id: 'filter.advancedSearch' })} />
                </div>
                <div className="modal-header__actionButtons">
                    <Button customClass="modal-header__actionButtons__left" value={intl.formatMessage({ id: 'filter.apply' })} onClick={onConfirm} />
                    <Button customClass="modal-header__actionButtons__right" value={intl.formatMessage({ id: 'filter.reset' })} onClick={onHandleReset} />
                </div>
            </Modal.Header>
            <Modal.Body>
                {renderFilterComponents()}
            </Modal.Body>
        </Modal>
        ) : (
            <div style={{ padding: '14px' }}>
                {renderFilterComponents()}
            </div>
        )}
        
        </>
    )
}

Filters.propTypes = {
    /** is modal open */
    showModal: PropTypes.bool,
    /** to use as modal */
    isModal: PropTypes.bool,
}

export default memo(injectIntl(Filters))