import axios from 'axios';
import { loadLocalState } from './local.svc';
// import qs from 'qs';

// const AUTH_URL = process.env.REACT_APP_AUTH;
const SCREENER_URL = process.env.REACT_APP_SCREENER;
const PRODUCT_DETAILS_URL = process.env.REACT_APP_PRODUCT_DETAILS;
const MASTER_DATA_URL = process.env.REACT_APP_MASTER_DATA;
const APPOINTMENT_URL = process.env.REACT_APP_APPOINTMENT;

/** Request interceptor */
axios.interceptors.request.use((config) => config, (error) => Promise.reject(error));
axios.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response;
  if (status === 401) {
    /** @Todo do something like clearing the states  */
    // localStorage.removeItem('SmartAdviceMO');
    // window.location.replace('/');

    // redirect to restricted login page
    window.location.replace('/restricted/login');
  }
  return Promise.reject(error);
});

/**
 * Get Screener or Product List
 //  * @param {*} token
 * @param {*} category [all, ] @Todo get all available categories we can use
 * @param alicePublic
 */
const fetchScreener = (category, alicePublic) => {
    const persistentState = loadLocalState();
    return axios({
        method: 'Get',
        headers: {
            key: alicePublic,
            // key: 'NWW##&*$@!1116',
            Authorization: `Bearer ${persistentState?.token}`, 
            channel: 'markaz' 
        },
        url: `${SCREENER_URL}/${category}?page=-1`
    })
}

const fetchProductDetailsById = (isin, alicePublic) => {
    const persistentState = loadLocalState();
    return axios({
        method: 'Get',
        headers: {
          channel: 'markaz',
          key: alicePublic,
          // key: 'NWW##&*$@!1116',
          Authorization: `Bearer ${persistentState?.token}`, 
        },
        url: `${PRODUCT_DETAILS_URL}/${isin}`
    })
}

/**
 * Get the Master Data or list of options
 */
 const getMasterData = () => { 
    return axios({
        method: 'GET',
        headers: { 
            // Authorization: `Bearer ${token}`, 
            channel: 'markaz' 
        },
        url: MASTER_DATA_URL,
    });
};

/**
 * Submit Appointment data to the back
 * @param {*} data 
 * @returns 
 */
const postAppointment = (data) => {
    return axios({
        method: 'Post',
        headers: { 
            // Authorization: `Bearer tokenhere`,
            channel: 'markaz' 
        },
        url: APPOINTMENT_URL,
        data
    })
}

const apiRequest = {
    fetchScreener,
    fetchProductDetailsById,
    getMasterData,
    postAppointment,
}

export default apiRequest;