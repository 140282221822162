import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as utils from 'shared/helpers/utils';

import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

import './ProductSlider.scss';

// const { createSliderWithTooltip } = Slider;
const { Handle } = Slider;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${utils.formatAmount(value)}`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

const ProductSlider = ({
    id,
    name,
    minValue,
    maxValue,
    minLabel,
    maxLabel,
    step,
    defaultValue,
    value,
    onChange = () => {},
    onAfterChange = () => {},
    isDisabled = false,
    isLabelHidden = false,
}) => {
    
    const classes = classnames({
        'product-slider-wrapper': true,
        'product-slider-wrapper--disabled': isDisabled,
        'product-slider-wrapper--labelHidden': isLabelHidden,
      });

    const marks = {
        [minValue]: minLabel, 
        [maxValue]: maxLabel,
    }

    const onHandleChange = (val) => {
      onChange(name, val)
    }

    return (
        <section className={classes} id={id}>
          <Slider
              className="product-slider-wrapper__slider"
              name={name}
              min={minValue}
              max={maxValue}
              step={step}
              defaultValue={defaultValue}
              marks={marks}
              value={value}
              onChange={onHandleChange}
              onAfterChange={onAfterChange}
              disabled={isDisabled}
              isLabelHidden={isLabelHidden}
              handle={handle}
          />
        </section>
    )
}

ProductSlider.propTypes = {
    /** Prop for id of slider */
    id: PropTypes.string,
    /** Prop for name of slider */
    name: PropTypes.string,
    /** Prop for the minimum value */
    minValue: PropTypes.number,
    /** Prop for the maximum value */
    maxValue: PropTypes.number,
    /** Prop for the current value */
    minLabel: PropTypes.string,
    /** Prop for the maximum label */
    maxLabel: PropTypes.string,
    /** Prop for the step */
    step: PropTypes.number,
    /** Prop for the default value */
    defaultValue: PropTypes.number,
    /** Prop for the marks */
    marks: PropTypes.any,
    /** Prop for value of slider */
    value: PropTypes.number,
    /** Prop for onChange */
    onChange: PropTypes.func,
    /** Prop for onAfterChange */
    onAfterChange: PropTypes.func,
    /** Prop for disabling */
    isDisabled: PropTypes.bool,
    /** Prop for hiding label */
    isLabelHidden: PropTypes.bool,
}

export default ProductSlider;