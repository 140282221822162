import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import './Tooltip.scss';

const Typography = loadable(() => import('../../components/Typography'));

const Tooltip = ({
    id,
    showTooltip = false,
    content,
}) => {
    return (
        <div className="tooltip" id={id}>
            {showTooltip ? <Typography type="subTitle" value={content} customClass="tooltip__content" /> : null }
        </div>
    )
}

Tooltip.propTypes = {
    /** Prop for id of the Tooltip */
    id: PropTypes.string,
    /** Prop for controlling the visibility of the Tooltip */
    showTooltip: PropTypes.bool,
    /** Prop for content of Tooltip */
    content: PropTypes.string,
}

export default Tooltip