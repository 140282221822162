import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import loadable from '@loadable/component';

import './Input.scss';

const Typography = loadable(() => import('../../components/Typography'));

const Input = ({
    id,
    name,
    type,
    placeholder = '',
    value,
    onChange = () => {},
    onClick = () => {},
    isReadOnly = false,
    isDisabled = false,
    isValid = true,
    errorMessage = 'Invalid Input',
    customClass,
}) => {
    const classes = classNames({
        "input-wrapper": true,
        "input-wrapper--invalid": !isValid,
        [customClass]: true,
    });

    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.id, e.target.value)
    }

    return (
        <div className={classes}>
            <input
                className="input-wrapper__input"
                id={id}
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onClick={onClick}
                readOnly={isReadOnly}
                disabled={isDisabled}
                autoComplete="off"
            />
            {!isValid && (
                <Typography customClass="input-wrapper--invalid" type="span" value={errorMessage} />
            )}
        </div>
    )
}

Input.propTypes = {
    /** Id of input */
    id: PropTypes.string,
    /** Name of input */
    name: PropTypes.string,
    /** Type of input */
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Placeholder of input */
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Value of input */
    value: PropTypes.string,
    /** On change function */
    onChange: PropTypes.func,
    /** On click function */
    onClick: PropTypes.func,
    /** Read only input */
	isReadOnly: PropTypes.bool,
    /** Disabling input */
    isDisabled: PropTypes.bool,
    /** Validity of input */
    isValid: PropTypes.bool,
    /** Error message */
    errorMessage: PropTypes.string,
}

export default Input;
