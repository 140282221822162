import React, { useState, memo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import { Modal, TimePicker } from 'components';

const Typography = loadable(() => import('../../components/Typography'));
const Button = loadable(() => import('../../components/Button'));

const SelectDate = ({
    intl,
    showModal,
    onClose = () => {},
}) => {
    const [timeValue, setTimeValue] = useState();

    const handleSubmit = (val) => {
        setTimeValue(val)
    };

    const handleBtnSubmit = (timeVal) => {
        onClose(timeVal)
    }

    return (
        <Modal isModalDynamic showModal={showModal} bodyPadding="0px 20px">
            <Modal.Header>
                <Typography type="span" isBold value={intl.formatMessage({ id: 'select.time.title' })} />
            </Modal.Header>
            <Modal.Body>
                <TimePicker
                    onSubmit={handleSubmit}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="selectDate__submitBtn" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        isPrimary
                        value={intl.formatMessage({ id: 'select.time.submit' })}
                        onClick={(e) => handleBtnSubmit(timeValue)}
                        isDisabled={timeValue && (timeValue === undefined || timeValue.length>0) ? false : true}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

SelectDate.propTypes = {
    /** is modal open */
    showModal: PropTypes.bool,
    /** Submit function */
    onChangeStep: PropTypes.func,
    /** On Close function */
    onClose: PropTypes.func,
}

export default memo(injectIntl(SelectDate))