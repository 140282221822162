import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './Modal.scss';

const Modal = ({
    customClassName,
    children = <></>,
    onClose = () => {},
    showModal = false,
    isModalDynamic = false,
    bodyPadding = '20px'
}) => {
    const modalRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose(event);
        }
    },[onClose]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    const header = React.Children.map(children, child => child.type.displayName === 'Header' ? <header className="modal__header" >{child}</header> : null);
    const body = React.Children.map(children, child => child.type.displayName === 'Body' ? <div className="modal__body" style={{ padding: bodyPadding }}>{child}</div> : null);
    const footer = React.Children.map(children, child => child.type.displayName === 'Footer' ? <div className="modal__footer">{child}</div> : null);

    if (showModal) {
        return ReactDOM.createPortal(
            <div className="modal-overlay">
                <section className={`${!isModalDynamic ? `modal ${customClassName}` : "modal-dynamic"}`} ref={modalRef}>
                    {header}
                    {body}
                    {footer}
                </section>
            </div>,
            document.querySelector('#app'),
        )
    }
    return null;
}

Modal.propTypes = {
    /** Custom classname */
    customClassName: PropTypes.string,
    /** Children node */
    children: PropTypes.node,
    /** Close the modal */
    onClose: PropTypes.func,
    /** Show modal boolean */
    showModal: PropTypes.bool,
    /** Prop for if component is dynamic */
    isModalDynamic: PropTypes.bool,
}

const Header = ({ children }) => children;
Header.displayName = 'Header';
Modal.Header = Header;

const Body = ({ children }) => children;
Body.displayName = 'Body';
Modal.Body = Body;

const Footer = ({ children }) => children;
Footer.displayName = 'Footer';
Modal.Footer = Footer;

export default Modal
