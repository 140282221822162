import React from 'react';
import Tag from './Tag';
import const_vars from 'shared/constant';
import PropTypes from 'prop-types';

import './Tags.scss';

const { TAGS_TYPE } = const_vars;

const Tags = ({ id = '' , data = [], geography = [], language }) => {

    const pinkTagValue = (tag) => {
        const allGeography = ["gcc countries", "usa", "europe"]
        const gccAndUsa = ["gcc countries", "usa"]
        const europeAndUsa = ["usa", "europe"]
        const gccAndEurope = ["gcc countries", "europe"]
        const gccOnly = ["gcc countries"]

        const lowerCaseGeography = [...geography]     
        for (var i = 0; i < lowerCaseGeography.length; i++) {
            lowerCaseGeography[i] = lowerCaseGeography[i].toLowerCase()
        }

        switch (true){
            case geography && allGeography.every((val) => lowerCaseGeography.includes(val.toLowerCase())):
                return `Global ${tag.value}`
                
            case geography && gccAndUsa.every((val) => lowerCaseGeography.includes(val.toLowerCase())):
                return `GCC, USA ${tag.value}`

            case geography && europeAndUsa.every((val) => lowerCaseGeography.includes(val.toLowerCase())):
                return `Europe, USA ${tag.value}`

            case geography && gccAndEurope.every((val) => lowerCaseGeography.includes(val.toLowerCase())):
                return `GCC, Europe ${tag.value}`
            
            case geography && gccOnly.every((val) => lowerCaseGeography.includes(val.toLowerCase())):
                return `GCC ${tag.value}`

            default:
                return `${geography} ${tag.value}`
        }
    }

    const getTagValue = (tag) => {
        switch (tag.type) {
            case TAGS_TYPE.CATEGORY1:
                return pinkTagValue(tag);

            case TAGS_TYPE.INVESTOR_TYPE:
                return "For Qualified Investors"

            default:
                return tag.value;
        }
    }
    return (
        <div className="tags">
            {data && data[0].hasOwnProperty('value') &&
                data.map((tag, idx) => (
                    tag.type !== TAGS_TYPE.INVESTOR_TYPE || tag.value.toLowerCase() === "qualified" ?
                        <Tag 
                            key={`${id}-${idx}`}
                            value={getTagValue(tag)}
                            isPink={tag.type === TAGS_TYPE.CATEGORY1}
                            isGold={tag.type === TAGS_TYPE.CATEGORY2}
                            isBlue={tag.type === TAGS_TYPE.INVESTOR_TYPE}
                            language={language}
                        />
                        :
                        <div key={`${id}-${idx}`}></div>
                ))
            }
        </div>
    )
}

Tags.propTypes = {
    /** data source */
    data: PropTypes.arrayOf(PropTypes.object),

    /** Geography */
    geography: PropTypes.arrayOf(PropTypes.string),

    /** Component ID */
    id: PropTypes.string,
}

export default Tags
