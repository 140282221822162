import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import getTranslation from 'shared/translations/getTranslation';

import './Tags.scss';

const Tag = ({ 
    value = 'Tag Value here', 
    isPink = false, 
    isGold = false, 
    isBlue = false,
    language
}) => {
    const classes = classNames({
        "tag": true,
        "tag--pink": isPink,
        "tag--gold": isGold,
        "tag--blue": isBlue
    });
    return (
        <span className={classes}>
            {getTranslation(value, language)}
        </span>
    )
}

Tag.propTypes = {
    /** Value of tag */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Pink tag */
    isPink: PropTypes.bool,
    /** Gold tag */
    isGold: PropTypes.bool,
    /** Blue tag */
    isBlue: PropTypes.bool
}

export default Tag
