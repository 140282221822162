import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Grid.scss';

const Grid = ({ children, displayByBlock = false }) => {
    const noOfChildren = Children.map(children, () => children.length).length;
    const classes = classNames({
        'grid': true,
        [`grid--col${noOfChildren}`]: true,
        'grid--byBlock': displayByBlock,
    })
    return (
        <section className={classes}>
            {Children.map(children, (child, i) => (
                <div key={`grid-${i}`} className="grid__item">
                    {child}
                </div>
            ))}
        </section>
    )
}

Grid.propTypes = {
    /** Child nodes */
    children: PropTypes.node,
    /** is Content by block */
    displayByBlock: PropTypes.bool,
}

export default Grid
