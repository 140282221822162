import React from 'react';
import ReactLoading from 'react-loading';

import './PageLoader.scss';

const PageLoader = () => {
    return (
        <section className="page-loader">
            <ReactLoading height="32px" width="32px" color="#00AEEF" type="spin" />
        </section>
    )
}

export default PageLoader;
