import { format, parse } from 'date-fns';
import { arSA } from 'date-fns/locale';
import numeral from 'numeral';
import kbpgp from 'kbpgp';
import { AES, enc, mode, pad } from 'crypto-js';
import axios from "axios";

const HANDSHAKE_URL = process.env.REACT_APP_HANDSHAKE;


// @Todo generate in JS instead of calling API
export const handshake = () => {
  return axios({
    method: 'POST',
    url: HANDSHAKE_URL
  })
}

export const decrypt = (data, key) => {
  try {
      const parsedBase64Key = enc.Base64.parse(key);
      const encryptedCipherText = data.toString();
      const decryptedData = AES.decrypt(encryptedCipherText, parsedBase64Key, {
        mode: mode.ECB,
        padding: pad.Pkcs7,
      });
      const decryptedTextOnBrowser = decryptedData.toString(enc.Utf8);
      return JSON.parse(decryptedTextOnBrowser);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

// export const decrypt = (data, alice) => {
//   if (!data) {
//     return {};
//   }
//   const ring = new kbpgp.keyring.KeyRing();
//   const kms = [alice];
//   for (const i in kms) {
//     ring.add_key_manager(kms[i]);
//   }
//   return new Promise((resolve, reject) => {
//     kbpgp.unbox({keyfetch: ring, armored: data}, function (err, literals) {
//       if (err != null) {
//         return reject(err);
//       } else {
//         resolve(literals[0].toString('utf8'));
//       }
//     });
//   });
// }

export const extractPrivateKey = (alice, passphrase) => {
  return new Promise((resolve, reject) => {
    alice.export_pgp_private ({
      passphrase: passphrase
    }, function(err, pgpPrivate) {
      resolve(pgpPrivate);
    });
  });
}

export const extractPublicKey = (alice) => {
  return new Promise((resolve, reject) => {
    alice.export_pgp_public({}, function(err, pgpPublic) {
      resolve(pgpPublic);
    });
  });
}

export const generateKey = (expire) => {
  const {ECDSA} = kbpgp.ecc;
  const F = kbpgp["const"].openpgp.key_flags
  const opts = {
    userid: "Markaz",
    primary: {
      nbits: 384,
      flags: F.certify_keys | F.sign_data | F.auth | F.encrypt_comm | F.encrypt_storage,
      expire_in: expire,
      algo: ECDSA
    },
    subkeys: [
      {
        nbits: 256,
        flags: F.sign_data,
        expire_in: expire
      }, {
        nbits: 256,
        flags: F.encrypt_comm | F.encrypt_storage,
        expire_in: expire
      }
    ]
  };
  return new Promise((resolve, reject) => {
    kbpgp.KeyManager.generate(opts, function(err, alice) {
      if (!err) {
        // sign alice's subkeys
        alice.sign({}, function(err) {
          if (!err) {
            resolve(alice);
          }
        });
      }
    });
  })
}

/**
 * Converts from date object to date string
 * in the format DD/MM/YYYY
 * @params date : date object
 * @returns date string e.g DD/MM/YYYY
 */
export const formatDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;

  const year = date.getFullYear();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return day + "/" + month + "/" + year;
};

/**
 * Converts from numeric epoch to date object
 * @params epoch : epoch (UNIX) time
 * @returns date object
 */
export const getDateFromEPOCH = (epoch) => {
  let date = new Date(0); // The 0 there is the key, which sets the date to the epoch
  if (epoch.length < 12) {
    date.setUTCSeconds(epoch);
  } else {
    date.setUTCMilliseconds(epoch);
  }
  return date;
};

/**
 * Converts date objects to date strings
 * in this format DD Month YYYY e.g. 24 Oct 2020
 * @params date : date object
 * @returns DD Month YYYY
 */
export const formatDateWithMonthName = (epoch) => {
  try {
    let date = new Date(epoch);
    date = (date).getTime() > 0 ? date : getDateFromEPOCH(epoch);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let day = date.getDate();
    let month = date.getMonth();

    const year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }

    if (isNaN(date)) throw new Error();
    return day + " " + monthNames[+month] + " " + year; 
    
  } catch (err) {
    return epoch;
  }
};


/**
 * Converts date strings from DD/MM/YYYY to MM/DD/YYYY or vice versa
 * in this format DD/MM/YYYY e.g. 24/10/2020
 * @params dateStr : 24/10/2020
 * @returns DD/MM/YYYY
 */
export const convertDDMMYYY = (dateStr) => {
  if (!dateStr || dateStr === "") {
    return "";
  }
  try {
    const vals = dateStr.split("/");
    return vals[1] + "/" + vals[0] + "/" + vals[2];
  } catch (e) {
    return "";
  }
};

/**
 * Converts EPOCH//UNIT TIMESTAMP into human readble date
 * in this format DD/MM/YYYY e.g. 24/10/2020
 * @params epoch : unixTimestamp
 * @returns DD/MM/YYYY
 */
export const convertEpochToDefaultDateFormat = (epoch) => {
  const newDate = getDateFromEPOCH(epoch);
  return formatDate(newDate);
};

/**
 * Parse Normal Date into a string the Date-Fns can understand
 * @param {*} date 
 */
export const parseIntoDateFNS = (date) => {
  return parse(date, 'dd/MM/yyyy', new Date())
}

/**
 * Format the date into dd/MM/yyyy e.g. 25/12/2020
 * @param {*} date 
 */
export const formatIntoDateFNS = (date) => {
  return format(date, 'dd/MM/yyyy')
}

/**
 * Convert number into decimal point
 * @param {*} value 
 */
const formatNumberToDecimal = (value) => {
    try {
      if (Number.isNaN(parseFloat(value))) return '';

      return new Intl.NumberFormat('en', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

    } catch (err) {
      return `error formatting ${err.message}`;
    }
};

/** format number into currency (with commas) */
export const formatAmountAsCurrency = amount => {
  if (!amount) return '-';
  return numeral(Number(amount)).format(0, 0);
};

/**
 * Format number
 * @param {*} amount 
 * @returns e.g. 10,000
 */
 export const formatAmount = (amount) => {
  if (!amount) return '-';
  return `${numeral(Number(amount)).format(0, 0)}`;
};

/**
 * Format number with currency sign
 * @param {*} amount 
 * @param {*} currency 
 * @returns e.g. 10,000 KWD
 */
export const formatAmountWithCurrency = (amount, currency) => {
  if (!amount || !currency) return '-';
  return `${numeral(Number(amount)).format(0, 0)} ${currency}`;
};

/**
 * Converts date strings from DD/MM/YYYY to DD MMM YYYY
 * in this format DD MMM YYYY e.g. 24 October 2020
 * @params dateStr : 24/10/2020
 * @returns 24 October 2020
 */
 export const convertToDD_MMM_YYYY = (dateStr) => {
  if (!dateStr || dateStr === "") {
    return "";
  }
  try {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const vals = dateStr.split("/");
    return `${vals[0]} ${monthNames[parseInt(vals[1])-1]} ${vals[2]}`

  } catch (e) {
    return "";
  }
};

/** Format string with % sign */
export const formatAsPercentage = (value) => {
    if (!value) return '-';
    return `${value} %`;
};

export const parseStringToInt = arr => {
  for (const index in arr.data) {
      for (const prop in arr.data[index]) {
          if (!isNaN(arr.data[index][prop])) {
              arr.data[index][prop] = parseInt(arr.data[index][prop])
          }
      }
  }
  return arr;
}

/**
 * Converts date objects to date strings
 * in this format Month YYYY e.g. Oct 2020
 * @params date : date object
 * @returns Month YYYY
 */
 const formatDateWithMonthYear = (epoch) => {
  try {
    let date = new Date(epoch);
    date = (date).getTime() > 0 ? date : getDateFromEPOCH(epoch);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let day = date.getDate();
    let month = date.getMonth();

    const year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }

    if (isNaN(date)) throw new Error();
    return monthNames[+month] + " " + year; 
    
  } catch (err) {
    return epoch;
  }
};

export const formatIntoARDate = (date, pattern = "MMM yyyy", isEpoch = true, isFullDate = false) => {
  if (!date) return null;
  let newDate = new Date(date);
  newDate = (newDate).getTime() > 0 ? newDate : new Date(date * 1000);
  if (!isEpoch) newDate = new Date(convertDDMMYYY(date));
  if (isNaN(newDate)) throw new Error();
  if (pattern === 'dd MMMM yyyy') {
    const monthNames = [
      'يناير',
      'فبراير',
      'مارس',
      'إبريل',
      'مايو',
      'يونيو',
      'يولية',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];
    let day = newDate.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    const result = `${day} ${monthNames[+month]}\u200E ${year}`;
    return result;
  }
  const result = format(newDate, pattern, { locale: arSA });
  //   const intlArFormat = intlFormat(newDate, {
  //      year: 'numeric',
  //      month: 'long',
  //      day: 'numeric',
  //    }, {
  //      locale: 'ar-SA',
  //  });
  //   return !isFullDate ? result : intlArFormat;
  return result;
}

const utils = {
    extractPublicKey,
    generateKey,
    formatNumberToDecimal,
    formatAsPercentage,
    formatDateWithMonthYear,
    formatIntoARDate,
}

export default utils;