import React, { lazy, Suspense, useContext } from 'react'; 
import { Switch, withRouter, Route, Redirect } from "react-router-dom";
import { CommonContext } from 'shared/context/store/commonStore';
import { FormattedMessage } from 'react-intl';
import { PageLoader } from 'components';
import { switchLanguage } from 'shared/context/actions';
import const_vars from 'shared/constant';
import { isMobile, isMobileOnly } from 'react-device-detect';
// import { Home, ProductDetails } from 'pages';

const { PAGE_URL, LANGUAGE } = const_vars;

/** Lazy Load pages */
const HomeLazy = lazy(() => import('./pages/Home'));
const ProductDetailsLazy = lazy(() => import('./pages/ProductDetails'));
const AppointmentLazy = lazy(() => import('./pages/Appointment'));
const ThankYouLazy = lazy(() => import('./pages/Appointment/ThankYou'));
const Login = lazy(() => import('./pages/Auth/Login/Login'));
const Otp = lazy(() => import('./pages/Auth/Otp/Otp'));

const Routes = (props) => {
    const { history } = props;
    const { state, dispatch } = useContext(CommonContext);

    /**
     * Language switcher
     */
    const onHandleSwitch = () => {
        if (state.language.locale === LANGUAGE.AR) switchLanguage(LANGUAGE.EN)(dispatch);
        else switchLanguage(LANGUAGE.AR)(dispatch);
    }

    return (
        <Suspense 
            fallback={(<PageLoader />)}
        > 
            <Switch>
                <Route 
                    path={PAGE_URL.RESTRICTED} 
                    exact 
                >
                    <Redirect to={PAGE_URL.LOGIN} />
                </Route>
                <Route 
                    path={PAGE_URL.LOGIN} 
                    exact 
                    component={(props) => (
                        <Login {...props} />
                    )}
                />
                <Route 
                    path={PAGE_URL.OTP} 
                    exact 
                    component={(props) => (
                        <Otp {...props} />
                    )}
                />
                <Route 
                    path={PAGE_URL.HOME} 
                    exact 
                    component={(props) => (
                        <HomeLazy 
                            isFilter={isMobile}
                            isHome 
                            navTitle={<FormattedMessage id={isMobileOnly ? "products" : "products.desktop"} />}
                            rightBtnValue={isMobile ? <FormattedMessage id="filter.advancedSearch" /> : (state.language.locale === LANGUAGE.EN ? 'عربي' : 'EN')} 
                            rightBtnClick={!isMobile ? onHandleSwitch : () => {}} 
                            {...props} 
                        />
                    )}
                />
                <Route 
                    path={`${PAGE_URL.PRODUCT_DETAILS}/:id`} 
                    component={(props) => (
                        <ProductDetailsLazy 
                            onBackBtnClick={() => history.push(PAGE_URL.HOME)} 
                            rightBtnClick={onHandleSwitch} 
                            rightBtnValue={state.language.locale === LANGUAGE.EN ? 'عربي' : 'EN'} 
                            navTitle={<FormattedMessage id="product.details" />}
                            locale={state.language.locale}
                            {...props} 
                        />
                    )} 
                />
                <Route
                    path={PAGE_URL.APPOINTMENT}
                    component={(props) => (
                        <AppointmentLazy
                            onBackBtnClick={() => history.goBack()} 
                            rightBtnClick={onHandleSwitch} 
                            rightBtnValue={state.language.locale === LANGUAGE.EN ? 'عربي' : 'EN'}
                            navTitle={<FormattedMessage id="appointment.request" />}
                            locale={state.language.locale}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={PAGE_URL.THANKYOU}
                    component={(props) => (
                        <ThankYouLazy
                            onBackBtnClick={() => history.goBack()} 
                            rightBtnClick={onHandleSwitch} 
                            rightBtnValue={state.language.locale === LANGUAGE.EN ? 'عربي' : 'EN'}
                            navTitle={<FormattedMessage id="thankyou.header" />}
                            locale={state.language.locale}
                            {...props}
                        />
                    )}
                />
            </Switch>
        </Suspense>
    )
};

export default withRouter(Routes);