import { toast } from 'react-toastify';

/**
 * 
 * @param {*} type : 'error' or 'success'
 * @param {*} message 
 * e.g. notifications('success', 'Form successfully sent!')
 */
const notifications = (type = 'error', message = '') => {
    const options = {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
    };
    toast.dismiss();
    
    if (type === 'error') {
        toast.error(message, options);
    } else {
        toast.success(message, options);
    }
}

export default notifications;