import { ACTION_TYPES } from 'shared/constant/actionTypes';
import english from 'shared/translations/en.json';
import arabic from 'shared/translations/ar.json';
import apiRequest from 'shared/services/api';

/** 
 * Language Switcher
 * @param id : either 'en' or 'ar'
 * @param dispatch
 * @returns { locale, messages }
 */
export const switchLanguage = (id) => (dispatch) => {
    const languages = {
      en: english,
      ar: arabic,
    };
  
    const payload = { locale: id, messages: languages[id] };
    dispatch({
      type: ACTION_TYPES.SAVE_LANGUAGE,
      payload,
    });
};

/**
 * Save Catalogue Product Detail on context
 * @param {*} payload : api response data
 * @returns object of product detail
 */
export const saveProductDetail =  payload => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.SAVE_PRODUCT_DETAIL,
        payload
    })
};

export const fetchMasterData = () => async (dispatch) => {
  try {
    const res = await apiRequest.getMasterData();
    await dispatch({
        type: ACTION_TYPES.UPDATE_MASTER_LIST,
        payload: res.data.EN.markaz_minimalInitialAmountUnit,
    })
    // return res.data.EN.markaz_minimalInitialAmountUnit;
  } catch(err) {
      console.log('err getting master data', err)
  }
}