import axios from 'axios';
import notifications from 'shared/helpers/notifications';
import { loadLocalState, LOCAL_STATE_KEY } from './local.svc';

const authChannel = process.env.REACT_APP_AUTH_CHANNEL;
const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const authAppName = process.env.REACT_APP_AUTH_APP_NAME;

const AuthRestClient = axios.create({
  baseURL: process.env.REACT_APP_UAA_BASE_URL,
  timeout: 15000,
});

AuthRestClient.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    // TODO: this will return response body
    if (status?.toString().startsWith('2') && response.data) return data;
    else return undefined;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestOtp = async (email, password) => {
  try {
    const params = new URLSearchParams();
    params.append('username', email.toLowerCase());
    params.append('password', password);
  
    const result = await AuthRestClient.post(`${authBaseUrl}/auth/login`, params, {
        headers: {
        channel: authChannel,
        appName: authAppName,
        },
    });

    return result;
  } catch (err) {
    notifications('error', 'Invalid login details');
    return err;
  }
};

const validateOtp = async (session, otp) => {
  try {
    const params = new URLSearchParams();
    params.append('session', session);
    params.append('otp', otp);

    const result = (await AuthRestClient.post(
        `${authBaseUrl}/auth/2-factor`,
        params,
        {
        headers: {
            channel: authChannel,
            appName: authAppName,
        },
        }
    ));
    return result;
  } catch (err) {
    notifications('error', err.message);
  }
};

const validateToken = async () => {
  const persistentState = loadLocalState();
  try {
    const result = await AuthRestClient.post(
      `${authBaseUrl}/auth/token`,
      null,
      {
        headers: {
          channel: authChannel,
          appName: authAppName,
          Authorization: `Bearer ${persistentState.token}`,
        },
      }
    );
    
    if (result === false) {
        localStorage.removeItem(LOCAL_STATE_KEY);
        window.location.replace('/');
    } 
    return result;
  } catch {
    // saveLocalState({ token: '' })
    localStorage.removeItem(LOCAL_STATE_KEY);
    window.location.replace('/');
    return false;
  }
};

const onResendOtp = async (session) => {
  try {
    return await AuthRestClient.post(`${authBaseUrl}/auth/2-factor/otp?session=${session}`);
    // return res?.session;
  } catch (err) {
    return err;
  }
}

const onLogout = async () => {
    const persistentState = loadLocalState();
    try {
        const res = await AuthRestClient.post(`${authBaseUrl}/quit?token=${persistentState?.token}`);
        if (res) {
            localStorage.removeItem(LOCAL_STATE_KEY);
            window.location.replace('/');
        }
    } catch (err) {
        console.error(err);
    }
}

export { requestOtp, validateOtp, validateToken, onLogout, onResendOtp };