export const LOCAL_STATE_KEY = 'E_CATALOGUE_AUTH';

export const loadLocalState = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STATE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveLocalState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STATE_KEY, serializedState);
  } catch (err) {
    return undefined;
  }
};