import React, { useState } from 'react';
import countryCodes from 'shared/helpers/countryCodes';
import ArrowIcon from 'assets/icons/icon-dropdown.svg';
import './CountryCodeDropdown.scss';

function CountryCodeDropdown({
  id,
  name,
  value,
  onChange = () => {},
}) {
  const [selected, setSelected] = useState(value);

  const parseSelected = (val) => {
    if (!val) return null;
    const filteredItem = countryCodes.filter(item => item.isoCode === val);
    return filteredItem[0].dialCode;
  };

  const onHandleChange = (e) => {
    setSelected(e.target.value);
    onChange(e, parseSelected(e.target.value));
  };

  return (
    <div className="country-select">
      <select
        id={id}
        name={name}
        value={selected}
        onChange={onHandleChange}
        style={{ backgroundImage: `url(${ArrowIcon})` }}
      >
        <option>Country</option>
        {countryCodes.map((item, idx) => (
          <option key={`co-${idx}`} value={item.isoCode}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CountryCodeDropdown;
