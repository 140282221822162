import { ACTION_TYPES } from 'shared/constant/actionTypes';
import const_vars from 'shared/constant';
// import { initialState } from '../store/catalogueStore';

const { PRODUCT_SERVICE_TYPE } = const_vars;

const catalogueReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_CATALOGUE:
            return {
                ...state,
                catalogue: action.payload,
            }
        case ACTION_TYPES.SAVE_PRODUCT_DETAIL:
            return {
                ...state,
                productDetail: action.payload
            }
        case ACTION_TYPES.UPDATE_MASTER_LIST:
            return {
                ...state,
                masterData: action.payload,
            }
        case ACTION_TYPES.UPDATE_SWITCH_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    switchFilter: action.payload,
                }
            };
        case ACTION_TYPES.ADD_FILTER_PRODUCT_TYPE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    productType: action.payload,
                }
            };
        case ACTION_TYPES.ADD_FILTER_GEOGRAPHY:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    geography: action.payload,
                }
            };
        case ACTION_TYPES.SAVE_MINIMUM_INVESTMENT:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    minimumInvestment: action.payload,
                }
            };
        case ACTION_TYPES.SAVE_MINIMUM_INVESTMENT_UNIT:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    minimumInvestmentUnit: action.payload,
                }
            };
        case ACTION_TYPES.IS_MIN_INVESTMENT_ACTIVE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    isMinInvestmentActive: action.payload,
                }
            };
        case ACTION_TYPES.RESET_FILTERS:
            /**
             * @Note for some reason, using this "initialState.filters" is not working
             * coz it's returning the context values instead of the defined initial states
             */
            return {
                ...state,
                // filters: {...initialState.filters},
                filters: {
                    productType: [{
                        label: PRODUCT_SERVICE_TYPE.MUTUAL_FUND,
                        name: 'mutualFund',
                        value: true
                    }, {
                        label: PRODUCT_SERVICE_TYPE.REAL_ESTATE,
                        name: 'realEstate',
                        value: true
                    }, {
                        label: PRODUCT_SERVICE_TYPE.INVESTMENT_SERVICE,
                        name: 'investmentService',
                        value: true
                    }],
                    geography: [{
                        label: 'GCC countries',
                        name: 'gccCountries',
                        value: true
                    }, {
                        label: 'USA',
                        name: 'usa',
                        value: true
                    }, {
                        label: 'Europe',
                        name: 'europe',
                        value: true
                    }],
                    isMinInvestmentActive: false,
                    minimumInvestment: 10000,
                    minimumInvestmentUnit: 'KWD',
                    switchFilter : {
                        "shariahOrAll": {
                            name: "shariahOrAll",
                            labels: ["filter.allInvestments", "filter.shariah"],
                            checkedState: [true, false]
                        },
                        "retailOrQualified": {
                            name: "retailOrQualified",
                            labels: ["filter.retailInvestor", "filter.qualifiedInvestor"],
                            checkedState: [false, true]
                        },
                    }        
                }
            }
        default:
            throw new Error();
    }
}

export default catalogueReducer;