import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import loadable from '@loadable/component';

import './TimePicker.scss';

const Typography = loadable(() => import('../../components/Typography'));

function TimePicker(props) {
  const {
    fromTime, toTime, onSubmit, rtl,
  } = props;

  const dataTime = [];

  for (let i = fromTime; i <= toTime; i++) {
    const timer = i * 10000;
    const formattedNumber = (`0${timer}`).slice(-6);
    const time = moment(formattedNumber, 'HHmmss').format('hh:mm A');
    dataTime.push(time);
  }
  
  const handleClick = (val) => {
    onSubmit(val);
  };

  return (
    <div className="time-picker">
      {dataTime.map((item, idx) => (
        <label className="time-picker__btn-label" key={idx}>
          <input
            type="radio"
            name="timePicker"
            rtl={rtl}
            onClick={() => handleClick(item)}
          />
          <span className="select-option__checkmark" />
          <Typography customClass="time-picker__btn-label__label" type="span" value={item} />


        </label>
      ))}
    </div>
  );
}

TimePicker.propTypes = {
  /** Start time of time-picker */
  fromTime: PropTypes.number,
  /** End time of time-picker */
  toTime: PropTypes.number,
  /** OnSubmit function */
  onSubmit: PropTypes.func,
};

TimePicker.defaultProps = {
  fromTime: 9,
  toTime: 15,
  onSubmit: () => {},
};

export default TimePicker;