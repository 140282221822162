import { ACTION_TYPES } from 'shared/constant/actionTypes';

const commonReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.SAVE_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            }
        // case ACTION_TYPES.OPEN_MODAL:
        //     return {
        //         ...state,
        //         openModal: action.payload,
        //     }
        default:
            throw new Error();
    }
}

export default commonReducer;