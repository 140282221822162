import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { Typography } from 'components';
import './List.scss';
import const_vars from 'shared/constant';

const { COLORS } = const_vars;

const List = ({
    list = [],
    isLink = false,
    coloredBullet = false,
    intl,
    isTranslated = false
}) => {

    return (
    <ul className="list" style={{listStyle:'none', paddingInlineStart:'unset', marginBlock:'unset'}}>
        {list && list.length > 0
            ? list.map((item, index) => {
                return (
                    <li className="list__content" key={`list${index}`}>
                        {coloredBullet && 
                            <div className="list__content__coloredBullet" style={{backgroundColor:COLORS[index]}}/>}
                        {isLink ? 
                            <a className="list__content__link" href={item.url} target="_blank" rel="noreferrer">
                                <Typography type="span" value={isTranslated ? intl.formatMessage({ id: item.label }) : item.label} />
                                <img alt="" src={item.value} />
                            </a>
                        :
                        <>
                            <Typography type="span" value={isTranslated ? intl.formatMessage({ id: item.label }) : item.label} />
                            <Typography type="span" value={item.value} />
                        </>}
                    </li>
        )}) : null}
    </ul>
    )
}

List.propTypes = {
    /** Prop for the list */
    list: PropTypes.array,
    /** Prop for the value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Prop for checking if the list is a link */
    isLink: PropTypes.bool,
    /** Prop for bullet point */
    coloredBullet: PropTypes.bool,
    /** is label translated */
    isTranslated: PropTypes.bool,
}

export default List;

