import React, { useContext } from 'react'
import { IntlProvider } from 'react-intl';
import { CommonContext } from 'shared/context/store/commonStore';

const ConnectedIntlProvider = ({ children }) => {
    const { state } = useContext(CommonContext);
    return (
        <IntlProvider locale={state.language.locale} defaultLocale="en" messages={state.language.messages}>
            {children}
        </IntlProvider>
    )
}

export default ConnectedIntlProvider
