import React from "react";
import "./Switch.scss";
import PropTypes from "prop-types";
import Typography from "components/Typography";

const Switch = ({
	label,
	name,
	value,
	onChange,
	id,
	isDisabled,
	isReadOnly,
}) => {
	// const [switchValue, setSwitchValue] = useState(value);
	const handleChange = (e) => {
		// setSwitchValue(!switchValue);
		onChange(e);
	};
	return (
		<div className="switch">
			<Typography value={label} type='span'/>
			<div className="switch__button-wrapper">
				<input
					className="switch__button-wrapper__checkbox"
					// value={(value = switchValue)}
					// checked={(value = switchValue)}
					value={value}
					checked={value}
					onChange={handleChange}
					id={id}
					type="checkbox"
					name={name}
					disabled={isDisabled}
					readOnly={isReadOnly}
				/>
				<label className="switch__button-wrapper__switch-container" htmlFor={id}>
					<div className="switch__button-wrapper__switch-container__button" />
				</label>
			</div>
		</div>
	);
};

Switch.propTypes = {
	/** Label for switch */
	label: PropTypes.string,

	/** Switch label string */
	id: PropTypes.string,

	/** Switch name */
	name: PropTypes.string,

	/** Disable switch */
	isDisabled: PropTypes.bool,

	/** Read Only Switch */
	isReadOnly: PropTypes.bool,

	/** Switch's state (true = Active or false = Inactive) */
	value: PropTypes.bool,

	/** Execute a JavaScript function when a user changes the selected option */
	onChange: PropTypes.func,
};

Switch.defaultProps = {
	label: "Label",
	name: "switch",
	value: false,
	id: "switch-id",
	onChange: () => {},
	isDisabled: false,
	isReadOnly: false,
};

export default Switch;
