import React from "react";
import { injectIntl } from 'react-intl';
import "./SwitchFilter.scss";
import PropTypes from "prop-types";

const SwitchFilter = ({
	name,
    labels,
	checked,
	onSelect,
	isDisabled,
	isReadOnly,
	labelMinHeight,
	labelFontSize,
	intl,
}) => {
    const mapFilters = labels.map((val, index) => 
			<div className={`switch-filter__button-container`} key={`${name}-${index}`}>
				<input
					className={`switch-filter__button-container__input`}
					type="radio"
					id={`${name}-${index}`}
					checked={checked[index]}
					disabled={isDisabled}
					readOnly={isReadOnly}
					onChange={()=>{}}
					onClick={(e) => onSelect(e.target.id)}
				/>
				<label 
					className={`switch-filter__button-container__label ${index===0 ? "switch-filter__button-container__label--left" : ""} ${index===labels.length-1 ? "switch-filter__button-container__label--right" : ""}` }
					htmlFor={`${name}-${index}`}
					style={{ minHeight: labelMinHeight, fontSize: labelFontSize }}
				>
					{intl.formatMessage({ id: val })}
				</label>
			</div>
		)
	return (
        <div className="switch-filter" key={name}>
			{mapFilters}
		</div>
	);
};

SwitchFilter.propTypes = {

    /** SwitchFilter name */
	name: PropTypes.string,

	/** Execute a JavaScript function when a user changes the selected option */
	onSelect: PropTypes.func,

    /** Label of switch buttons */
    labels: PropTypes.arrayOf(PropTypes.string),

    /** State of radio buttons, on or off */
    checked: PropTypes.arrayOf(PropTypes.bool),

	/** Disable switch */
	isDisabled: PropTypes.bool,

	/** Read Only SwitchFilter */
	isReadOnly: PropTypes.bool,

};

SwitchFilter.defaultProps = {
	name: "switch-filter",
    labels: ["left button", "right button"],
	checked: [true, false],
	onSelect: () => {},
	isDisabled: false,
	isReadOnly: false,
};

export default injectIntl(SwitchFilter);