import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import './FieldsetContainer.scss';

const Typography = loadable(() => import('../../components/Typography'));

const FieldsetContainer = ({
    label = '',
    id,
    children,
    customClass,
}) => {

    return (
        <div className="fieldsetContainer" id={id}>
            {label &&
                <Typography customClass="fieldsetContainer__label" type="span" isBold value={label} />}
            <div className={`fieldsetContainer__body ${customClass}`}>
                {children}
            </div>
        </div>
    )
}

FieldsetContainer.propTypes = {
    /** Label of fieldset-container */
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /** Id of fieldset-container */
    id: PropTypes.string,
    
    /** Children node */
    children: PropTypes.node,

    /** extra className */
    customClass: PropTypes.string,
}

export default FieldsetContainer;