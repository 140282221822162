import React from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';


const Dropdown = ({ name='dropdown', isDisabled = false, defaultValue, options, onChange=()=>{} }) => {

    const handleOnChange = (e) => {
        onChange(e)
    }
    return (
        <select className='dropdown' name={name} disabled={isDisabled} value={defaultValue} onChange={handleOnChange} >
            {options.map((obj, index) => (
                <option value={obj} key={index}>{obj}</option>
            ))}
        </select>
    )
}

Dropdown.propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    option: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
}

export default Dropdown