import React, { useReducer, createContext, useMemo } from 'react';
import commonReducer from '../reducers/commonReducer';
import const_vars from 'shared/constant';

import english from 'shared/translations/en.json';
// import arabic from 'shared/translations/ar.json';

const { LANGUAGE } = const_vars;

const initialState = {
    language: {
        locale: LANGUAGE.EN, 
        messages: english
    },
}

export const CommonContext = createContext();

export const CommonContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(commonReducer, initialState);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <CommonContext.Provider value={contextValue}>
          {children}
        </CommonContext.Provider>
    );
}